<template>
  <div class="app-item app-content">
    <h4>员工观看记录</h4>
    <el-row>
      <el-col>
        <span>真实姓名：</span>
        <el-select v-model="real_name" clearable placeholder="请选择" @change="getErecords()">
          <el-option
            v-for="(item,ind) in employees"
            :key="ind"
            :label="item.real_name"
            :value="item.real_name">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-table style="width: 100%" :data="tableData">
      <el-table-column prop="real_name" label="阅读者" align="center"></el-table-column>
      <el-table-column prop="article.title" label="文章" align="center"></el-table-column>
      <el-table-column prop="created_at" label="开始时间" align="center"></el-table-column>

      <el-table-column prop="updated_at" label="结束时间" align="center"></el-table-column>
    </el-table>
     <Page :total="total" @goTo="goTo"></Page>
  </div>
</template>
<script>
import Page from '../../components/Page.vue'
import qs from 'qs'
export default {
  components: { Page },
  data () {
    return {
      total: 0,
      real_name: '',
      tableData: [],
      employees: []
    }
  },
  methods: {
    // 页码
    goTo (page) {
      this.getErecords(page)
    },
    // 获取员工列表
    getEmployees () {
      this.$store.dispatch('getEmployees').then(res => {
        console.log('员工：', res)
        this.employees = res.data
      })
    },
    // 获取员工阅读记录
    getErecords (page) {
      let obj = {}
      if (page) {
        obj = Object.assign({}, obj, { page })
      }
      if (this.real_name) {
        obj = Object.assign({}, obj, { real_name: this.real_name })
      }
      let objString = qs.stringify(obj)
      this.$store.dispatch('getErecords', objString).then(res => {
        console.log(res)
        this.tableData = res.data
        this.total = res.total
      })
    }
  },
  mounted () {
    this.getEmployees()
    this.getErecords()
  }
}
</script>
<style lang="less" scoped>
.app-item{
  .el-row{
    .el-col{
      margin:0 20px 20px;
      margin-right:10px
    }
  }
}
</style>
